<template>
  <v-container>
    <v-row class="text-center pa-1">
      <v-col cols="12">
        <v-img
          :src="require('../assets/escudo_estique.png')"
          contain
          class="mx-auto logo"
        />
      </v-col>
      <v-col cols="12">
        <h2 class="title">Sistema de Mesa de partes virtual</h2>
      </v-col>

      <v-col cols="12" class="information">
        <p>Horario de recepción y registro</p>
        <p>
          Lunes a Viernes de <span class="information__hour">08:00 am</span> a
          <span class="information__hour">02:45pm</span>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotAvailable",

  data: () => ({}),
};
</script>

<style  scoped>
.title {
  font-weight: 500;
  text-transform: uppercase;
  color: black;
  font-size: 2.3em;
}

.subtitle {
  font-size: 1em;
  text-transform: uppercase;
}
.information {
  padding: 1.5em 1em;
}
.information__hour {
  font-weight: bold;
}
.logo {
  max-width: 500px;
  height: 140px;
  width: 100%;
}
</style>