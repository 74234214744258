import { render, staticRenderFns } from "./TramiteExterno.vue?vue&type=template&id=d01f3902&scoped=true&"
import script from "./TramiteExterno.vue?vue&type=script&lang=js&"
export * from "./TramiteExterno.vue?vue&type=script&lang=js&"
import style0 from "./TramiteExterno.vue?vue&type=style&index=0&id=d01f3902&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d01f3902",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VAppBar,VContainer,VImg,VProgressCircular})
